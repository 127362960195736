import styled from "@emotion/styled";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import StudiesCard from "./studies-card.component";


class StudiesGrid extends React.Component {

  constructor() {
    super()

  }

  shouldComponentUpdate(nextProps, nextState) {

    const { transitionStatus, entry, exit } = nextProps;
    console.log('- Render Study Grid (shouldComponentUpdate)', nextProps.transitionStatus);

    return transitionStatus.indexOf('exit') < 0;
  }
  render() {
    console.log('- Render Study Grid', this.props.transitionStatus);
    if (this.props.transitionStatus.indexOf('exit') >= 0) {
      console.trace();
    }
    return (
      <StaticQuery
        query={graphql`
          query {
            studies: allPrismicCases {
                  nodes {
                    id
                    uid
                    data{
                      title {
                        text
                      }
                      by {
                        document{
                          data{
                            name
                          }
                        }
                      }
                      image {
                        ...prismicCasesDataImageFragment
                      }
                      
                    }
                  }
              }
            }
        `
        }
        render={data => {
          return (
            <Studies>
              {data.studies.nodes.map(product => (
                <StudiesCard key={product.uid} product={product} transitionStatus={this.props.transitionStatus} />
              ))}
            </Studies>
          )
        }}
      />

    )
  }
}

export default StudiesGrid


const Studies = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: scroll;
  min-height: 100vh;
`
