import React from "react";
// import Header from '../components/header/header';
import StudiesGrid from '../components/studies.grid/studies.grid';

class BlogIndex extends React.Component {
  render() {
    // const { data } = this.props
    return (
      <>
        {/* <Header /> */}
        <StudiesGrid transitionStatus={this.props.transitionStatus} />
      </>
    )
  }
}

export default BlogIndex
