import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { responsiveFont, settings } from "./settings";


export const H1 = styled("h1")`
  margin-top: 0;
  ${responsiveFont(settings.typo.h1)};
`;

export const H2 = styled("h2")`
  margin-top: 0;
  ${responsiveFont(settings.typo.h2)};
`;

export const H3 = styled("h3")`
  margin-top: 0;
  ${responsiveFont(settings.typo.h3)};
`;

export const H4 = styled("h4")`
  margin-top: 0;
  ${responsiveFont(settings.typo.h4)};
`;

export const H5 = styled("h5")`
  margin-top: 0;
  ${responsiveFont(settings.typo.h5)};
`;

export const H6 = styled("h6")`
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: .2em;
  ${responsiveFont(settings.typo.h6)};
`;

export const P = styled("p")`
  ${responsiveFont(settings.typo.p)};
`;


export const external_link = css`
  ${responsiveFont(settings.typo.small)};
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: .19em;
  padding: 7px 0;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #000;
  text-decoration: none;

  &:after{
    content: "";
    background-image: url(/images/link-arrow.svg);
    padding-left: 30px;
    background-repeat: no-repeat;
    background-position: right center;
  }

  &:hover{
    text-decoration: none;
  }
`;

export const normal_link = css`
  ${responsiveFont(settings.typo.small)};
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: .19em;
  padding: 7px 0;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #000;
  text-decoration: none;

  &:hover{
    text-decoration: none;
  }
`;

export const Input = styled("input")`
  ${responsiveFont(settings.typo.small)};
`;


